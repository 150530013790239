import React from "react"
import CurrentHeroSection from "../components/CurrentHeroSection/CurrentHeroSection"
import SectionContainer from "../components/shared/SectionContainer"
import TextContainer from "../components/shared/TextContainer"
import { graphql } from "gatsby"
import CurrentOrganizations from "../components/CurrentOganizations/CurrentOrganizations"
import Activities from "../components/Activities/Activities"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Travel from "../components/Travel/Travel"
import TravelLog from "../components/TravelLog/TravelLog"
import LetMeHelp from "../components/LetMeHelp/LetMeHelp"
import SectionTitle from "../components/shared/SectionTitle"
import SectionSubtitle from "../components/shared/SectionSubtitle"
import JoinSection from "../components/JoinSection/JoinSection"

const CurrentPage = props => {
  const {
    data: {
      prismicCurrentLife: { data },
    },
    location,
  } = props
  return (
    <Layout path={location.pathname}>
      <SEO title="Current" />
      <CurrentHeroSection title={data.header.text} tagline={data.tagline} />
      <SectionContainer id="strategies">
        <TextContainer>
          <SectionTitle>{data.professional_title.text}</SectionTitle>
          <SectionSubtitle>{data.professional_tagline.text}</SectionSubtitle>
        </TextContainer>
      </SectionContainer>
      <CurrentOrganizations organizations={data.body} />
      <SectionContainer id="activities">
        <TextContainer>
          <SectionTitle>{data.activities_title.text}</SectionTitle>
          <SectionSubtitle>{data.activities_tagline.text}</SectionSubtitle>
        </TextContainer>
      </SectionContainer>
      <Activities activities={data.activities} />
      <SectionContainer id="adventure">
        <TextContainer>
          <SectionTitle>{data.travel_title.text}</SectionTitle>
          <SectionSubtitle>{data.travel_tagline.text}</SectionSubtitle>
        </TextContainer>
      </SectionContainer>
      <Travel travel={data.travel} />
      <TravelLog log={data.travel_log} />
      <LetMeHelp />
      <JoinSection />
    </Layout>
  )
}

export default CurrentPage

export const currentPageQuery = graphql`
  query currentPageQuery {
    prismicCurrentLife {
      data {
        header {
          text
        }
        professional_title {
          text
        }
        professional_tagline {
          text
        }
        body {
          primary {
            org_title {
              text
            }
            org_position {
              text
            }
            org_description {
              text
            }
            highlights {
              html
            }
            goals {
              html
            }
            org_image {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
        activities_title {
          text
        }
        activities_tagline {
          text
        }
        activities {
          title {
            text
          }
          subtitle {
            text
          }
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
        travel_title {
          text
        }
        travel_tagline {
          text
        }
        travel {
          title {
            text
          }
          subtitle {
            text
          }
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
        travel_log {
          title {
            text
          }
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`
