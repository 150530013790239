import styled from "styled-components"
import { breakpoints } from "../../utils/styles"

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

export const FlexItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 100%;
  margin-bottom: 2rem;

  @media (min-width: ${breakpoints.md}) {
    flex: 0 0 50%;
  }

  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 0;
    flex: 0 0 33.333333%;
  }
`

export const FlexTitle = styled.h6`
  font-family: var(--header-font);
  font-size: 1.75rem;
  margin: 1.875rem 0 0;
`

export const FlexSubtitle = styled.p`
  font-size: 1.125rem;
  margin: 1rem 0 0;
`
