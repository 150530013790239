import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import SplitContainer from "../shared/SplitContainer"
import { breakpoints } from "../../utils/styles"
import OrganizationText from "../OrganizationText/OrganizationText"

const ImgContainer = styled.div`
  order: 1;

  @media (min-width: ${breakpoints.xl}) {
    order: ${props => (props.left ? "2" : "1")};
  }
`

const CurrentOrganizations = ({ organizations }) => {
  return (
    <>
      {organizations.map((organization, index) => {
        const {
          org_title,
          org_position,
          org_description,
          org_image,
          highlights,
          goals,
        } = organization.primary
        const left = index % 2 === 0
        return (
          <SplitContainer leftSmaller={left} key={index}>
            <OrganizationText
              title={org_title}
              position={org_position}
              description={org_description}
              highlights={highlights}
              goals={goals}
            />
            <ImgContainer left={left}>
              <Img fluid={org_image.localFile.childImageSharp.fluid} />
            </ImgContainer>
          </SplitContainer>
        )
      })}
    </>
  )
}

export default CurrentOrganizations
