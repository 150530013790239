import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import {
  FlexContainer,
  FlexItem,
  FlexSubtitle,
  FlexTitle,
} from "../shared/FlexContainer"

const StyledImg = styled(Img)`
  height: 100%;
  width: 100%;
`

const Activities = ({ activities }) => {
  return (
    <FlexContainer>
      {activities.map((activity, index) => {
        return (
          <FlexItem key={index}>
            <StyledImg fluid={activity.image.localFile.childImageSharp.fluid} />
            <FlexTitle>{activity.title.text}</FlexTitle>
            <FlexSubtitle>{activity.subtitle.text}</FlexSubtitle>
          </FlexItem>
        )
      })}
    </FlexContainer>
  )
}

export default Activities
