import React from "react"
import styled from "styled-components"
import SplitContainer from "../shared/SplitContainer"
import { breakpoints } from "../../utils/styles"
import ScrollLink from "../shared/ScrollLink"

const StyledSplitContainer = styled(SplitContainer)`
  padding: 3rem 2rem;
`

const Title = styled.h1`
  text-transform: uppercase;
  justify-self: left;
  font-size: 2.5rem;
  align-self: center;

  @media (min-width: ${breakpoints.md}) {
    font-size: 3.125rem;
  }

  @media (min-width: ${breakpoints.xl}) {
    justify-self: right;
    padding-right: 3.75rem;
  }
`

const Subtitle = styled.div`
  font-size: 1.5rem;
  line-height: 1.75;
  align-self: center;

  @media (min-width: ${breakpoints.md}) {
    font-size: 1.75rem;
    line-height: 2;
  }

  @media (min-width: ${breakpoints.xxl}) {
    font-size: 2rem;
  }

  a {
    color: #000;
    font-weight: 700;
    display: inline-block;
    text-decoration: none;
    position: relative;

    @keyframes border-underline {
      from {
        width: 0;
      }
      to {
        width: 100%;
      }
    }

    :after {
      position: absolute;
      left: 0;
      content: "";
      display: block;
      margin: auto;
      height: 3px;
      width: 0;
      background: #000;
      animation: 1s ease 500ms forwards border-underline;
    }
  }
`

const Linebreak = styled.br`
  display: none;

  @media (min-width: ${breakpoints.md}) {
    display: initial;
  }
`

const CurrentHeroSection = ({ title }) => {
  return (
    <StyledSplitContainer leftSmaller>
      <Title>{title}</Title>
      <Subtitle>
        <span>Professionally driven by </span>
        <ScrollLink scrollId="strategies">tech-centric</ScrollLink> solutions
        with a purpose.
        <Linebreak />
        <span> Personally living an </span>
        <ScrollLink scrollId="adventure">adventurous</ScrollLink>
        <span>, </span>
        <ScrollLink scrollId="activities">experimental</ScrollLink> lifestyle.
      </Subtitle>
    </StyledSplitContainer>
  )
}

export default CurrentHeroSection
