import React from "react"
import styled from "styled-components"
import { breakpoints } from "../../utils/styles"

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: ${breakpoints.xl}) {
    grid-template-columns: ${props =>
      props.leftSmaller ? "2fr 3fr" : "3fr 2fr"};
  }
`

const SplitContainer = ({ children, leftSmaller, className }) => {
  return (
    <Container className={className} leftSmaller={leftSmaller}>
      {children}
    </Container>
  )
}

export default SplitContainer
