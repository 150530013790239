import React from "react"
import Img from "gatsby-image"
import SectionContainer from "../shared/SectionContainer"
import styled from "styled-components"
import ImgContainer from "../shared/ImgContainer"
import { breakpoints } from "../../utils/styles"
import SectionTitle from "../shared/SectionTitle"

const StyledSectionTitle = styled(SectionTitle)`
  color: #999999;
  margin-bottom: 4rem;
`

const LogContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (min-width: ${breakpoints.xl}) {
    grid-template-columns: repeat(6, 1fr);
  }
`

const LogItem = styled.div`
  display: flex;
  flex-direction: column;
`

const LogTitle = styled.h6`
  font-family: var(--header-font);
  text-transform: uppercase;
  font-size: 1.25rem;
  margin: 1rem 0 0;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 1.5rem;
  }
`

const TravelLog = ({ log }) => {
  return (
    <SectionContainer background="#f6f6f6">
      <StyledSectionTitle>Travel log history</StyledSectionTitle>
      <LogContainer>
        {log.map(history => {
          return (
            <LogItem key={history.title.text}>
              <ImgContainer>
                <Img fluid={history.image.localFile.childImageSharp.fluid} />
              </ImgContainer>
              <LogTitle>{history.title.text}</LogTitle>
            </LogItem>
          )
        })}
      </LogContainer>
    </SectionContainer>
  )
}

export default TravelLog
