import React from "react"
import styled from "styled-components"
import {
  FlexContainer,
  FlexItem,
  FlexSubtitle,
  FlexTitle,
} from "../shared/FlexContainer"
import Img from "gatsby-image"
import ImgContainer from "../shared/ImgContainer"
import { breakpoints } from "../../utils/styles"

const StyledFlexContainer = styled(FlexContainer)`
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 2rem;
  }
`

const Travel = ({ travel }) => {
  return (
    <StyledFlexContainer>
      {travel.map(item => {
        return (
          <FlexItem key={item.title.text}>
            <ImgContainer>
              <Img fluid={item.image.localFile.childImageSharp.fluid} />
            </ImgContainer>
            <FlexTitle>{item.title.text}</FlexTitle>
            <FlexSubtitle>{item.subtitle.text}</FlexSubtitle>
          </FlexItem>
        )
      })}
    </StyledFlexContainer>
  )
}

export default Travel
